export const HeaderData = {
    moes: {
        logoPath: "/moes/logo.png",
        locationsURL: "",
        logoAlt: "Moes Logo",
        benefitsLogo: "/common/aarp-benefits.png",
        benfitsAlt: "AARP Logo"
    },
    moestmobile: {
        logoPath: "/moes/moes-tmobile-logo.png",
        logoAlt: "Moe's & T-Mobile Tuedays Logos",
        logoUrl: "/tmobiletuesdaysnov12",
    },
    aamuttggenroll: {
        logoPath: "/auntieannes/aa-mut-logo.png",
		logoUrl: "/mutggenroll",
        locationsURL: "",
        logoAlt: "Auntie Anne's & MUT's Logos",
    },
    aamuttgg5off25: {
        logoPath: "/auntieannes/aa-mut-logo.png",
		logoUrl: "/mutgg5off25",
        locationsURL: "",
        logoAlt: "Auntie Anne's & MUT's Logos",
    },
    aamuttggbogo: {
        logoPath: "/auntieannes/aa-mut-logo.png",
		logoUrl: "/mutggbogo6m29p02s",
        locationsURL: "",
        logoAlt: "Auntie Anne's & MUT's Logos",
    },
    aamuttgg5off10: {
        logoPath: "/auntieannes/aa-mut-logo.png",
		logoUrl: "/mut5off1004a03t04s",
        locationsURL: "",
        logoAlt: "Auntie Anne's & MUT's Logos",
    },
    aamuttgg10off30: {
        logoPath: "/auntieannes/aa-mut-logo.png",
		logoUrl: "/mutgg10off3012m05g05s",
        locationsURL: "",
        logoAlt: "Auntie Anne's & MUT's Logos",
    },
    aatmobile: {
        logoPath: "/auntieannes/aa-t-mobile-logo.png",
		logoUrl: "/tmobiletuesdaysoct22",
        locationsURL: "",
        logoAlt: "Auntie Anne's & T-Mobile Tuesdays Logos",
    },
    aatmoaug27: {
        logoPath: "/auntieannes/aa-t-mobile-logo.png",
		logoUrl: "/tmobiletuesdaysaug27",
        locationsURL: "",
        logoAlt: "Auntie Anne's & T-Mobile Tuesdays Logos",
    },
    schlotzskystmobile: {
        logoPath: "/schlotzskys/logotmobile.png",
		logoUrl: "/tmobiletuesdayssept10",
        locationsURL: "",
        logoAlt: "Schlotzsky's & T-Mobile Tuesdays Logos",
    },
    mcalisterstmobile: {
        logoPath: "/mcalisters/logomcatmobile.png",
		logoUrl: "/tmobiletuesdayssept24",
        locationsURL: "",
        logoAlt: "Mcalister's & T-Mobile Tuesdays Logos",
    },
    cinnabontmobile: {
        logoPath: "/cinnabon/logocintmobile.png",
		logoUrl: "/tmobiletuesdaysoct15",
        locationsURL: "",
        logoAlt: "Cinnabon & T-Mobile Tuesdays Logos",
    },
    schlotzskys: {
        logoPath: "/schlotzskys/logo.png",
        locationsURL: "https://locations.schlotzskys.com",
        logoAlt: "Schlotzsky's Logo",
        benefitsLogo: "/common/aarp-benefits.png",
        benfitsAlt: "AARP Logo"
    },
    schlotzskysauntieannes: {
        logoUrl: "/csschaun",
        logoPath: "/schlotzskys/logo_slgaa.png",
        locationsURL: "https://locations.schlotzskys.com",
        logoAlt: "Schlotzsky's Logo"
    },
    mcalisters: {
        logoPath: "/mcalisters/logo.svg",
        locationsURL: "https://locations.mcalistersdeli.com",
        logoAlt: "McAlister's Deli Logo",
        benefitsLogo: "/common/aarp-benefits.png",
        benfitsAlt: "AARP Logo"
    },
    mcalisterscinnabon: {
        logoPath: "/mcalisters/logo_mccin.png",
		logoUrl: "/csmcacin",
        locationsURL: "https://locations.mcalistersdeli.com",
        logoAlt: "McAlister's Deli Logo"
    },
    auntieannes: {
        logoPath: "/auntieannes/logo-aa.png",
        locationsURL: "https://locations.auntieannes.com",
        logoAlt: "McAlister's Deli Logo",
        benefitsLogo: "/common/aarp-benefits.png",
        benfitsAlt: "AARP Logo"
    },
    auntieannesschlotzskys: {
        logoUrl: "/csaunsch",
        logoPath: "/auntieannes/logo_aaslg_rs.png",
        locationsURL: "https://locations.auntieannes.com",
        logoAlt: "Auntie Anne's & Schlotzsky's logo"
    },
    jamba: {
        logoPath: "/jamba/logo.svg",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Logo",
        benefitsLogo: "/common/aarp-benefits.png",
        benfitsAlt: "AARP Logo"
    },
    jambatmobile: {
        logoPath: "/jamba/jatmobile-logo.png",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Logo",
		logoUrl: "/tmobiletuesdaysoct8",
    },
    jambaauntieannes: {
        logoPath: "/jamba/logo_jamaaa.png",
        logoUrl: "/csjamaun",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Logo"
    },
    auntieannesjamba: {
        logoPath: "/auntieannes/logo_aajam_rs.png",
        logoUrl: "/csaunjam",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Logo"
    },
    cinnabon: {
        logoUrl: "/",
        logoPath: "/cinnabon/logo.svg",
        benefitsLogo: "/common/aarp-benefits.png",
        locationsURL: "https://locations.cinnabon.com",
        logoAlt: "Cinnabon's Logo"
    },
    carvel: {
        logoPath: "/carvel/logo.png",
        locationsURL: "https://locations.carvel.com",
        logoAlt: "Carvel's Logo"
    },
    fetchmca: {
        logoPath: "/fetchmca/logo.png",
		logoUrl: "/fetch",
        locationsURL: "",
        logoAlt: "Fetch's Logo"
    },
    fetchsl: {
        logoPath: "/fetchsl/logo.png",
		logoUrl: "/fetch",
        locationsURL: "",
        logoAlt: "Fetch's Logo"
    },
    fetchci: {
        logoPath: "/fetchci/logo.png",
		logoUrl: "/fetch",
        locationsURL: "",
        logoAlt: "Fetch's Logo"
    },
    cinnabonmcalisters: {
        logoPath: "/cinnabon/logo_cimc.png",
		logoUrl: "/cscinmca",
        locationsURL: "",
        logoAlt: "Cinnabon and McAlister's logos"
    },
    cinnabonmoes: {
        logoPath: "/cinnabon/logo_cinmoe.png",
		logoUrl: "/cscinmoe",
        locationsURL: "",
        logoAlt: "Fetch's Logo"
    },
    moescinnabon: {
        logoPath: "/moes/logo_moecin.png",
		logoUrl: "/csmoecin",
        locationsURL: "",
        logoAlt: "Fetch's Logo"
    },
    channelsaa: {
        logoPath: "/channelsaa/logo.png",
		logoUrl: "/",
        locationsURL: "",
        logoAlt: "Auntie Anne's Logo"
    },
    channelsci: {
        logoPath: "/cinnabon/logo.svg",
		logoUrl: "/",
        locationsURL: "",
        logoAlt: "Cinnabon's Logo"
    },
    fetchja: {
        logoPath: "/fetchja/logo.png",
		logoUrl: "/fetch",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Logo"
    },
    fetchaa: {
        logoPath: "/fetchaa/fetch-logo-small.png",
		logoUrl: "/fetch",
        locationsURL: "",
        logoAlt: "Auntie Anne's Logo"
    },
    fuelja: {
        logoPath: "/jamba/fuel-rewards-logo.png",
		logoUrl: "/fuelrewards",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Fuel Rewards Logo"
    },
    fueljalto: {
        logoPath: "/jamba/fuel-rewards-logo.png",
		logoUrl: "/fuelrewardslto",
        locationsURL: "https://locations.jamba.com",
        logoAlt: "Jamba's Fuel Rewards Logo"
    },
    sweepsmoes: {
        logoPath: "/sweepsmoes/logo.png",
        locationsURL: "/",
        logoAlt: "Sweepstake Coca-Cola & Moes Logo"
    },
    sweepsmca: {
        logoPath: "/mcalisters/logo.svg",
        locationsURL: "/",
        logoAlt: "Sweepstake McAlister's Dr Pepper"
    },
    sweepsjamba: {
        logoPath: "/jamba/jamba-disney-logo.png",
        locationsURL: "/",
        logoAlt: "Sweepstakes Jamba Disney"
    },
}