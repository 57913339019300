export const NotFoundData = {
    moes: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    schlotzskys: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    mcalisters: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    auntieannes: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aamuttggenroll: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aamuttgg5off25: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aamuttggbogo: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aamuttgg5off10: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aamuttgg10off30: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aatmobile: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    aatmoaug27: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    jambatmobile: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    schlotzskystmobile: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    mcalisterstmobile: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    cinnabontmobile: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    jamba: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    sweepsjamba: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    jambaauntieannes: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    auntieannesjamba: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    auntieannesschlotzskys: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    cinnabonmcalisters: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    cinnabonmoes: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    mcalisterscinnabon: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    moescinnabon: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    schlotzskysauntieannes: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    cinnabon: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    carvel: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    fetchmca: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    fuelja: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
    fueljalto: {
        pageTitle: "ERROR 404 - Page Not Found",
    },
}