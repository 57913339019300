import React from "react";
import BrandContext from "../common/contexts/brand";
import Hero from "../components/Hero";
import { LandingData } from "../data/Landing.data";
import { Brand, POLL_ID } from "../common/constants/constants";

class LandingPoll extends React.Component {
  render() {
    let brand = Brand;
    const Data = LandingData[brand];

    return (
      <main className="main-content">
        <Hero
          backgroundImage={Data.heroBackgroundImage}
          heroTitle={Data.heroTitle}
          brandingLogoMobile={Data.heroBrandingLogo}
          brandingLogoAltMobile={Data.heroBrandingLogoAlt}
          heroDescription={Data.heroDescription}
          signUpSubtitle={Data.heroSignUpSubtitle}
          signUpUrl={Data.heroSignUpUrl}
          signUpButton={Data.heroSignUpButton}
          linkAccountSubtitle={Data.heroLinkAccountSubtitle}
          linkAccountButton={Data.heroLinkAccountButton}
          brandingLogoDesktop={Data.heroBrandingLogo}
          heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
          showBgOnMobile={Data.showBgOnMobile}
        />
        <section class="container">
          <h1 class="highlighter"><a href="#vote-now">Take me to vote</a></h1>
          <h2 class="section-title">The new character will find its way into our freezers & hearts starting 2025!</h2>
          <br />
          <p class="info-wrapper">Carvel has been a place for Fudgie fanatics to eat, laugh, & enjoy the delicious company of wacky characters like Fudgie The Whale&reg;, Cookie Puss&reg;, & Hug Me Bear&reg; since 1934. So not surprisingly, it's time to introduce another character to our deliciously zany crew.</p>
          <img class="divider" src="/carvel/divider.png" />
        </section>
        <section class="container">
        <div class="row">
          <div class="col"><div class="card-component secondary-style"><img class="char" src={Data.step2.imageURL} alt="character 2" /><h2 class="section-title small">{Data.step2.title}</h2><p class="description">{Data.step2.line1.prelinkText}</p></div></div>
          <div class="col"><div class="card-component secondary-style"><img class="char" src={Data.step1.imageURL} alt="character 1" /><h2 class="section-title small">{Data.step1.title}</h2><p class="description">{Data.step1.line1.prelinkText}</p></div></div>
          <div class="col"><div class="card-component secondary-style"><img class="char" src={Data.step3.imageURL} alt="character 3" /><h2 class="section-title small">{Data.step3.title}</h2><p class="description">{Data.step3.line1.prelinkText}</p></div></div>
        </div>
        <img class="divider" src="/carvel/divider.png" id="vote-now" />
        <br /><br />
        </section>

        <div className="container">
          <div id='woobox-root'></div>
          <script>
            {(function (d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "https://woobox.com/js/plugins/woo.js";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'woobox-sdk'))};
          </script>
          <div 
            className="woobox-offer" 
            data-offer={POLL_ID}
            data-trigger="enter"
          ></div> 
        </div>
        <br />
        <img class="divider" src="/carvel/divider.png" id="vote-now" />
        <br />
        <section class="container">
        <img class="char" src="/carvel/fudgie.png" id="fudgie-the-whale" />
        <img class="char title" src="/carvel/intro3PD.png" id="intro3PD" />
        <p class="description"><b>From 6/12 - 6/25, get $5 off Fudgie The Whale&reg; cakes</b> through Uber Eats, Grubhub, or DoorDash. Valid at participating shoppes and while supplies last. Fees and taxes may apply; see checkout for details.</p>
        <h1 class="highlighter"><a href="https://www.carvel.com/delivery?utm_source=ce-landing&utm_id=vote2023">Order now</a></h1>
          </section>
        <div className="container">
          <p className="faint-text text-center"></p>
        </div>
      </main>
    );
  }

}

LandingPoll.contextType = BrandContext;

export default LandingPoll;
