// Create a new footer if the default does not apply
const JambaDefaultFooter = {
	copyright: "Copyright 2025 © Jamba Juice Franchisor SPV LLC. All rights reserved.",
	footerLinks: [
		{
			text: "Legal",
			URL: "https://www.jamba.com/legal",
			target: "_blank",
		},
		{
			text: "Privacy",
			URL: "https://www.jamba.com/privacy",
			target: "_blank",
		},
		{
			text: "CA Privacy",
			URL: "https://www.jamba.com/caprivacy",
			target: "_blank",
		},
		{
			hasTruyoPrivacyOptions: true
		},
		{
			text: "Responsible Disclosure",
			URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
			target: "_blank",
		},
		{
			text: "Animal Welfare",
			URL: "https://www.focusbrands.com/animal-welfare",
			target: "_blank",
		},
		{
			text: "Accessibility",
			URL: "https://www.focusbrands.com/accessibility/jamba",
			target: "_blank",
		},
		{
			text: "Sitemap",
			URL: "https://www.jamba.com/sitemap",
			target: "_blank",
		},
	],
};

export const FooterData = {
	moes: {
		copyright: "© 2025 Moe’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.moes.com/site-map",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.moes.com/legal",
				target: "_blank",
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.moes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.moes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/moes/",
				target: "_blank",
			},
		],
	},
	moestmobile: {
		copyright: "© 2025 Moe’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.moes.com/site-map",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.moes.com/legal",
				target: "_blank",
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.moes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.moes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/moes/",
				target: "_blank",
			},
		],
	},
	schlotzskys: {
		copyright: "© COPYRIGHT 2025 SCHLOTZSKYS ALL RIGHTS RESERVED",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.schlotzskys.com/sitemap",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.schlotzskys.com/privacy",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.schlotzskys.com/legal",
				target: "_blank",
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928/bd0d45d1-0c3c-4a36-8654-b80263240fd8",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/schlotzskys",
				target: "_blank",
			},
		],
	},
	schlotzskystmobile: {
		copyright: "© COPYRIGHT 2025 SCHLOTZSKYS ALL RIGHTS RESERVED",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.schlotzskys.com/sitemap",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.schlotzskys.com/privacy",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.schlotzskys.com/legal",
				target: "_blank",
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928/bd0d45d1-0c3c-4a36-8654-b80263240fd8",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/schlotzskys",
				target: "_blank",
			},
		],
	},
	mcalisters: {
		copyright: "© 2025 McAlister's Franchisor SPV LLC",
		logo: {
			src: "/mcalisters/mca-mini-logo.png",
			alt: "",
			url: "https://www.mcalistersdeli.com/"
		},
		footerLinks: [
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.mcalistersdeli.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.mcalistersdeli.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.mcalistersdeli.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928/70e10b95-6524-45bf-a033-ce59d1f5d200",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/mcalisters-deli/",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.mcalistersdeli.com/sitemap",
				target: "_blank",
			},
		],
		navLinks: [
			{
				text: "Rewards",
				URL: "https://www.mcalistersdeli.com/rewards",
				target: "_blank",
			},
			{
				text: "About",
				URL: "https://www.mcalistersdeli.com/about-us",
				target: "_blank",
			},
			{
				text: "Careers",
				URL: "https://www.mcalistersdeli.com/careers",
				target: "_blank",
			},
			{
				text: "Newsroom",
				URL: "https://www.mcalistersdeli.com/news",
				target: "_blank",
			},
			{
				text: "Contact Us",
				URL: "https://survey3.medallia.com/?mcalisters-contact-us&br=5",
				target: "_blank",
			},
			{
				text: "Faq",
				URL: "https://www.mcalistersdeli.com/faq",
				target: "_blank",
			},
			{
				text: "Franchising",
				URL: "https://development.focusbrands.com/",
				target: "_blank",
			},
		]
	},
	mcalisterscinnabon: {
		copyright: "© 2023 McAlister’s Franchisor SPV LLC",
		logo: {
			src: "/mcalisters/mca-mini-logo.png",
			alt: "",
			url: "https://www.mcalistersdeli.com/"
		},
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.mcalistersdeli.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.mcalistersdeli.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.mcalistersdeli.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
		],
		navLinks: [
			{
				text: "Rewards",
				URL: "https://www.mcalistersdeli.com/rewards",
				target: "_blank",
			},
			{
				text: "About",
				URL: "https://www.mcalistersdeli.com/about-us",
				target: "_blank",
			},
			{
				text: "Careers",
				URL: "https://www.mcalistersdeli.com/careers",
				target: "_blank",
			},
			{
				text: "Newsroom",
				URL: "https://www.mcalistersdeli.com/news",
				target: "_blank",
			},
			{
				text: "Contact Us",
				URL: "https://survey3.medallia.com/?mcalisters-contact-us&br=5",
				target: "_blank",
			},
			{
				text: "Faq",
				URL: "https://www.mcalistersdeli.com/faq",
				target: "_blank",
			},
			{
				text: "Franchising",
				URL: "https://development.focusbrands.com/",
				target: "_blank",
			},
		]
	},
	moescinnabon: {
		copyright: "© 2023 Moe’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.moes.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.moes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.moes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
		],
	},
	fetchaa: {
		copyright: "© 2025 Fetch Rewards, Inc., All rights reserved. Fetch and the Fetch logo are trademarks of Fetch Rewards, Inc.​</br>© 2025 Auntie Anne’s Franchisor SPV LLC.",
		footerLinks: [
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/privacy#ca",
				target: "_blank",
			},
			{

				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes/",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
		],
	},
	fetchsl: {
		copyright: "© 2023 Fetch and GoTo Foods",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.schlotzskys.com/sitemap",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.schlotzskys.com/privacy",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.schlotzskys.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			// {
			//     text: "Your Privacy Choices",
			//     URL: "#cookieBanner",
			// },
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/schlotzskys/",
				target: "_blank",
			},
		],
	},
	auntieannes: {
		copyright: "© 2025 Auntie Anne’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{

				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes/",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact Us",
				URL: "https://survey3.medallia.com/?auntieannes-contact-us&br=1",
				target: "_blank",
			}
		],
	},
	aamuttggenroll: {
		copyright: `© 2025 Auntie Anne’s Franchisor SPV LLC © 2025 Stormstrike. All rights reserved.
		<a href="https://www.mut.gg/terms/" target="_blank">Terms and conditions</a>`,
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aamuttgg5off25: {
		copyright: `© 2025 Auntie Anne’s Franchisor SPV LLC © 2025 Stormstrike. All rights reserved.
		<a href="https://www.mut.gg/terms/" target="_blank">Terms and conditions</a>`,
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aamuttggbogo: {
		copyright: `© 2025 Auntie Anne’s Franchisor SPV LLC © 2025 Stormstrike. All rights reserved.
		<a href="https://www.mut.gg/terms/" target="_blank">Terms and conditions</a>`,
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aamuttgg5off10: {
		copyright: `© 2025 Auntie Anne’s Franchisor SPV LLC © 2025 Stormstrike. All rights reserved.
		<a href="https://www.mut.gg/terms/" target="_blank">Terms and conditions</a>`,
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aamuttgg10off30: {
		copyright: `© 2025 Auntie Anne’s Franchisor SPV LLC © 2025 Stormstrike. All rights reserved.
		<a href="https://www.mut.gg/terms/" target="_blank">Terms and conditions</a>`,
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aatmobile: {
		copyright: "© 2025 Auntie Anne’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	aatmoaug27: {
		copyright: "© 2025 Auntie Anne’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/auntie-annes",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.auntieannes.com/sitemap",
				target: "_blank",
			},
			{
				text: "Contact us",
				URL: "https://www.auntieannes.com/contact-us",
				target: "_blank",
			},
		],
	},
	auntieannesschlotzskys: {
		copyright: "© 2023 Auntie Anne’s Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.auntieannes.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.auntieannes.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.auntieannes.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
		],
	},
	mcalisterstmobile: {
		copyright: "© 2025 McAlister's Franchisor SPV LLC",
		logo: {
			src: "/mcalisters/mca-mini-logo.png",
			alt: "",
			url: "https://www.mcalistersdeli.com/"
		},
		footerLinks: [
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.mcalistersdeli.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.mcalistersdeli.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.mcalistersdeli.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928/70e10b95-6524-45bf-a033-ce59d1f5d200",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/mcalisters-deli/",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.mcalistersdeli.com/sitemap",
				target: "_blank",
			},
		],
		navLinks: [
			{
				text: "Rewards",
				URL: "https://www.mcalistersdeli.com/rewards",
				target: "_blank",
			},
			{
				text: "About",
				URL: "https://www.mcalistersdeli.com/about-us",
				target: "_blank",
			},
			{
				text: "Careers",
				URL: "https://www.mcalistersdeli.com/careers",
				target: "_blank",
			},
			{
				text: "Newsroom",
				URL: "https://www.mcalistersdeli.com/news",
				target: "_blank",
			},
			{
				text: "Contact Us",
				URL: "https://survey3.medallia.com/?mcalisters-contact-us&br=5",
				target: "_blank",
			},
			{
				text: "Faq",
				URL: "https://www.mcalistersdeli.com/faq",
				target: "_blank",
			},
			{
				text: "Franchising",
				URL: "https://development.focusbrands.com/",
				target: "_blank",
			},
		]
	},
	// Jamba
	jamba: JambaDefaultFooter,
	fetchja: JambaDefaultFooter,
	jambaauntieannes: JambaDefaultFooter,
	fuelja: JambaDefaultFooter,
	fueljalto: JambaDefaultFooter,
	sweepsjamba: JambaDefaultFooter,

	cinnabon: {
		copyright: "© 2025 Cinnabon® Franchisor SPV LLC. All rights reserved",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.cinnabon.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.cinnabon.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.cinnabon.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/cinnabon/",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.cinnabon.com/sitemap",
				target: "_blank",
			},
		],
	},
	cinnabontmobile: {
		copyright: "© 2025 Cinnabon® Franchisor SPV LLC. All rights reserved",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.cinnabon.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.cinnabon.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.cinnabon.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/cinnabon/",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.cinnabon.com/sitemap",
				target: "_blank",
			},
		],
	},
	jambatmobile: JambaDefaultFooter,
	channelsci: {
		copyright: "© 2025 Cinnabon® Franchisor SPV LLC. All rights reserved",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.cinnabon.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.cinnabon.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.cinnabon.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
			{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/cinnabon/",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare/",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.cinnabon.com/sitemap",
				target: "_blank",
			},
		],
	},
	cinnabonmcalisters: {
		copyright: "© 2023 Cinnabon Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.cinnabon.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.cinnabon.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.cinnabon.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
		],
	},
	cinnabonmoes: {
		copyright: "© 2023 Cinnabon Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Legal",
				URL: "https://www.cinnabon.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.cinnabon.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.cinnabon.com/caprivacy",
				target: "_blank",
			},
			{
				hasTruyoPrivacyOptions: true
			},
		],
	},
	carvel: {
		copyright: "© 2021 Carvel Franchisor SPV LLC",
		footerLinks: [
		],
	},
	fetchmca: {
		copyright: "© 2025 Fetch Rewards, Inc., All rights reserved. Fetch and the Fetch logo are trademarks of Fetch Rewards, Inc.​</br> © 2025 McAlister's Franchisor SPV LLC",
		footerLinks: [
			{
				text: "Animal Wellfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.mcalistersdeli.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy Policy",
				URL: "https://www.mcalistersdeli.com/privacy",
				target: "_blank",
			},
			{
				text: "Your Privacy Choices",
				URL: "#cookieBanner",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/mcalisters-deli",
				target: "_blank",
			},
			{
				text: "Sitemap",
				URL: "https://www.mcalistersdeli.com/sitemap",
				target: "_blank",
			},
		],
	},
	fetchsl: {
		copyright: "©2025 Fetch Rewards, Inc., All rights reserved. Fetch and the Fetch logo are trademarks of Fetch Rewards, Inc.</br>© Copyright 2025 Schlotzsky’s All Rights Reserved",
		footerLinks: [
			{
				text: "Sitemap",
				URL: "https://www.schlotzskys.com/sitemap",
				target: "_blank",
			},
			{
				text: "Privacy",
				URL: "https://www.schlotzskys.com/privacy",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.schlotzskys.com/legal",
				target: "_blank",
			},{
				text: "Responsible Disclosure",
				URL: "https://focusbrands.onspring.com/Survey/c8f3c589-0151-4e3c-bc73-4f1089ab2928",
				target: "_blank",
			},
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			// {
			//     text: "Your Privacy Choices",
			//     URL: "#cookieBanner",
			// },
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/schlotzskys/",
				target: "_blank",
			},
		],
	},
	sweepsmca: {
		copyright: "© 2023 McAlister's Deli and GoTo Foods",
		logo: {
			src: "/mcalisters/mca-mini-logo.png",
			alt: "",
			url: "https://www.mcalistersdeli.com/"
		},
		footerLinks: [
			{
				text: "Animal Welfare",
				URL: "https://www.focusbrands.com/animal-welfare",
				target: "_blank",
			},
			{
				text: "Legal",
				URL: "https://www.mcalistersdeli.com/legal",
				target: "_blank",
			},
			{
				text: "Privacy Policy",
				URL: "https://www.mcalistersdeli.com/privacy",
				target: "_blank",
			},
			{
				text: "CA Privacy",
				URL: "https://www.mcalistersdeli.com/caprivacy",
				target: "_blank",
			},
			{
				text: "Accessibility",
				URL: "https://www.focusbrands.com/accessibility/mcalisters-deli",
				target: "_blank",
			},
			{
				text: "sitemap",
				URL: "https://www.mcalistersdeli.com/sitemap",
				target: "_blank",
			},
		],
		navLinks: [
			{
				text: "Rewards",
				URL: "https://www.mcalistersdeli.com/rewards",
				target: "_blank",
			},
			{
				text: "About",
				URL: "https://www.mcalistersdeli.com/about-us",
				target: "_blank",
			},
			{
				text: "Careers",
				URL: "https://www.mcalistersdeli.com/careers",
				target: "_blank",
			},
			{
				text: "Newsroom",
				URL: "https://www.mcalistersdeli.com/news",
				target: "_blank",
			},
			{
				text: "Contact Us",
				URL: "https://survey3.medallia.com/?mcalisters-contact-us&br=5",
				target: "_blank",
			},
			{
				text: "Faq",
				URL: "https://www.mcalistersdeli.com/faq",
				target: "_blank",
			},
			{
				text: "Franchising",
				URL: "https://development.focusbrands.com/",
				target: "_blank",
			},
		]
	},
}

