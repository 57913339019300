export const SweepsEntryData = {
  sweepsmca: {
    title: "Step 2: Enter the sweeps for a chance to win!",
    joinSubtitle: "",
    description: "",
    rewardsDisclaimer: `NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING. Sweepstakes begins on or about 9/1/23 at 8:00 a.m. ET and ends on 9/30/23 at 11:59 p.m. ET. Open only to legal U.S. residents who are 21 years of age or older. See Official Rules at https://sweeps.mcalistersdeli.com/DrPepperBig12/rules including additional eligibility restrictions, prize descriptions/restrictions/ARVs and complete details. Odds of winning depend on the number of eligible entries received. Limit one (1) entry per person. Void where prohibited. Sponsor: McAlister’s Franchisor SPV LLC. Dr Pepper is a registered trademark of Dr Pepper/Seven Up, Inc. 2023.`,
    joinButton: "Enter Sweeps",
    agePrefix: `I agree to the <a href="/drpepperbig12/rules">Official Rules</a> and that I am 21 years of age or older at the time of entry.`,
    ageLinkText: "",
    ageLinkURL: "",
    termsPrefix: "Lorem ipsum",
    formErrorMessages: {
      ageCheckbox: {
        required: "Age must be checked"
      },
      checkbox: {
        required: "",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    showTermsCheckbox: false,
  },
  sweepsmoes: {
    title: "Step 2: Register for the Sweeps and Enter for a Chance to Win!",
    joinSubtitle: "",
    joinButton: "Register",
    description: "Register for the Moe's Sounds of Summer Sweepstakes for a chance to win the grand prize of VIP tickets to a 2025 Music Festival or a $200 gift card.",
    agePrefix: "I affirm that I am 18 years or older and eligible in all ways for Moe's Sounds of Summer Sweepstakes per the ",
    ageLinkText: "Official Rules",
    ageLinkURL: "/soundsofsummer/rules",
    termsPrefix: "Yes, I have read and agree to the official rules and sponsor’s ",
    termsLinkText: "privacy policy",
    termsLinkURL: "https://www.moes.com/privacy",
    termsHTML: "I agree to Moe's Terms and conditions to the following: I agree to Moe's <a href='https://www.moes.com/legal' target='_blank'>Terms and conditions</a>, the <a href='https://www.moes.com/legal#rewards-program' target='_blank'>Moe Rewards Terms</a>, and the use of my personal information as explained in the <a href='https://www.moes.com/privacy' target='_blank'>Privacy Policy</a>. If I am a CA resident, I consent to the <a href='https://www.moes.com/privacy#Financial' target='_blank'>Financial Incentive</a> associated with this loyalty program.",
    isTermsExternal: true,
    formErrorMessages: {
      ageCheckbox: {
        required: "Age must be checked"
      },
      checkbox: {
        required: "Terms and Conditions must be checked",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    showTermsCheckbox: true,
  },
  sweepsjamba: {
    title: "Step 2: Enter the sweeps for a chance to win!",
    joinSubtitle: "",
    description: "",
    rewardsDisclaimer: ``,
    joinButton: "Enter Sweeps",
    agePrefix: `I agree to the <a href="/concert/rules">Official Rules</a> and that I am 21 years of age or older at the time of entry.`,
    ageLinkText: "",
    ageLinkURL: "",
    termsPrefix: "Lorem ipsum",
    formErrorMessages: {
      ageCheckbox: {
        required: "Age must be checked"
      },
      checkbox: {
        required: "",
      },
      recaptcha: {
        required: "reCAPTCHA is required",
      },
    },
    apiFieldErrorMessages: {
      recaptcha: {
        invalid: "reCAPTCHA validation failed",
      },
    },
    showTermsCheckbox: false,
  },
}