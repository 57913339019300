import React from "react";
import { withRouter } from "react-router-dom";
import { updatePartner } from "../api/updatePartner";
import { Brand, PARTNERS } from "../common/constants/constants";
import ContentContext from "../common/contexts/content";
import { getUser, saveUser } from "../common/user";
import Disclaimer from "../components/Disclaimer";
import Hero from "../components/Hero";
import SignUpForm from "../components/SignUpForm";
import { isFetchMca } from "../common/helpers/isFetchMca";
import { isFetchSl } from "../common/helpers/isFetchSl";

class FetchSignUp extends React.Component {
  handleSuccess = async () => {
    const { token } = getUser();
    // link fetch partner
    const params = new URLSearchParams(this.props.location.search);
    const userId = params.get("userId");
    let user = await updatePartner(
      token,
      PARTNERS.Partner2,
      undefined,
      userId || undefined
    );
    saveUser(user);

    // redirect to success
    this.props.history.replace("/fetch/success");
  };

  getData() {
    return this.context.landingData;
  }

  render() {
    const Data = this.getData();
    return (
      <>
        <main className="main-content">
          <Hero
            backgroundImage={Data.heroBackgroundImage}
            heroTitle={Data.heroTitle}
            heroNote={Data.heroNote}
            brandingLogoMobile={Data.heroBrandingLogo}
            brandingLogoAltMobile={Data.heroBrandingLogoAlt}
            heroDescription={Data.heroDescription}
            linkAccountSubtitle={Data.heroLinkAccountSubtitle}
            linkAccountButton={Data.heroLinkAccountButton}
            brandingLogoDesktop={Data.heroBrandingLogo}
            heroBrandingLogoAltDesktop={Data.heroBrandingLogoAlt}
            showBgOnMobile={Data.showBgOnMobile}
          />
          <section
            className={`container ${isFetchSl() ? "fetchsl-container" : ""} ${
              isFetchMca() ? "fetchmca-container" : ""
            } `}
          >
            <div className="row">
              <div className="col">
                <h1>{Data.pageTitle}</h1>
                {Brand === "cinnabon" && (
                  <p className="cin-rewards-text">{Data?.rewardsText}</p>
                )}
                <p className={`info-wrapper ${isFetchSl() && "fetchsl-info"}`}>
                  {Data.infoText}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SignUpForm onSuccess={this.handleSuccess} />
              </div>
            </div>
            <div className="container">
              <Disclaimer disclaimerText={Data.rewardsDisclaimer} />
              <Disclaimer disclaimerText={Data.disclaimerText} />
            </div>
          </section>
        </main>
      </>
    );
  }
}

FetchSignUp.contextType = ContentContext;

export default withRouter(FetchSignUp);
