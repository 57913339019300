export default {
  logo: {
    path: "/channelsaa/logo-big.png",
    alt: "Auntie Anne's Logo"
  },
  tools: [
    {
      href: "https://locations.auntieannes.com",
      text: "Locations",
      thirdParty: true
    },
    {
      href: "https://www.auntieannes.com/grocery-products",
      text: "Grocery Products"
    },
    {
      href: "https://www.auntieannes.com/our-story/pretzel-pals",
      text: "K-12"
    },
    {
      href: "https://www.auntieannes.com/contact-us",
      text: "Contact Us",
      thirdParty: true
    },
    {
      href: "https://www.auntieannes.com/gift-cards",
      text: "Gift Cards"
    },
    {
      href: "https://www.auntieannes.com/contact-us/careers",
      text: "Careers"
    },
    {
      href: "https://www.auntieannes.com/-/media/auntie-annes/files/auntie-annes-nutritional-guide.pdf?v=1&d=20180530T163306Z",
      text: "Nutrition"
    },
    {
      href: "https://www.auntieannes.com/contact-us/common-questions",
      text: "FAQ"
    },
    {
      href: "https://www.auntieannes.com/newsroom",
      text: "Media Releases"
    },
  ],
  copyrightText: "©2025 AUNTIE ANNE'S FRANCHISOR SPV LLC​",
  copyright: [
    {
      href: "https://www.auntieannes.com/legal",
      text: "Legal"
    },
    {
      href: "https://www.focusbrands.com/animal-welfare",
      text: "Animal Welfare",
      customClass: "fcs-third-party"
    },
    {
      href: "https://www.focusbrands.com/accessibility/auntie-annes",
      text: "Accessibility",
      customClass: "fcs-third-party"
    },
    {
      href: "https://www.auntieannes.com/privacy",
      text: "Privacy"
    },
    {
      href: "https://www.auntieannes.com/ca-privacy",
      text: "CA Privacy"
    },
    {
      hasTruyoPrivacyOptions: true,
      href: "#",
      id: "cookieSettingButton",
      customClass: "cookieSettingButton",
      text: "Your Privacy Choices"
    },
    {
      href: "https://www.auntieannes.com/sitemap",
      text: "Sitemap"
    },
  ],
  adaDisclaimer: "indicates link opens an external site which may or may not meet accessibility guidelines."
}