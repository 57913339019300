import FetchCiFooterData from "../../data/Footer/FetchCiFooter.data";
import { SocialURLData } from "../../data/Social.data";

export function FetchCiFooter() {
  // markup is taken from the main website in order to reflect the brand look and feel: https://www.cinnabon.com/
  const socialData = SocialURLData.cinnabon;

  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="site-tools">
            <nav className="auxiliary-navigation">
              <ul>
                {FetchCiFooterData.tools.map(link => {
                  return (
                    <li className="nav-item">
                      <a
                        href={link.href}
                        className={[
                          "nav-link",
                          link.thirdParty ? "fcs-third-party" : ""
                        ].join(" ")}
                        target="_blank"
                      >
                        {link.text}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <p>
              {FetchCiFooterData.copyrightFetch}
              </p>
              <p>
                {FetchCiFooterData?.copyrightCokeFetch}
              </p>
            </nav>
          </div>
          <div className="copyright">
            <p>
              {FetchCiFooterData.copyrightText}
            </p>
            <ul>
              {FetchCiFooterData.copyright.map((link, index) => {
                return (
                  <li>
                    <a
                      href={link.href}
                      className={`nav-link ${link.customClass ? link.customClass : ""}`}
                      target="_blank"
                      id={link.id}
                    >
                      {link.text}
                      {link.hasTruyoPrivacyOptions && (
                        <span className="privacy-icon">
                          <img src="/common/privacy-icon.svg" />
                        </span>
                      )}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}