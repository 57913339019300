import React from "react";
import BrandContext from "../../common/contexts/brand";
import { LinkingData } from "../../data/Linking.data";
import Joi from 'joi';
import { getUser, saveUser } from "../../common/user";
import { updatePartner } from "../../api/updatePartner";
import { getPartnerInfo } from "../../api/getPartnerInfo";
import SignIn from "../SignIn";
import { processApiError } from "../../common/helpers/processApiError";
import { Brand } from "../../common/constants/constants";
import { isFuelJa, isFuelJaLto } from "../../common/helpers/isFuelJa";
import { withRouter } from 'react-router-dom';

const Data = LinkingData[Brand];
class Linking extends React.Component {
  formSchema = Joi.object({
    partnerProgram: Joi.string().required().messages({
      'string.empty': Data.formErrorMessages.partnerProgram.required,
    }),
    memberId: Joi.string().min(10).required().messages({
      'string.empty': Data.formErrorMessages.memberId.required,
      'string.min': Data.formErrorMessages.memberId.invalid,
    }),
  });

  constructor(props) {
    super(props);
    
    const pathSegments = window.location.pathname.split("/");
    const urlAccountNumber = pathSegments.length >= 5 ? pathSegments[4] : null;

    // pull information from url into route state; this will trigger the
    // constructor to be called again
    if (urlAccountNumber) {
      this.props.history.replace(this.props.match.path, { accountNumber: urlAccountNumber });
    }

    this.formRef = React.createRef();
    let user = getUser();
    this.state = {
      attemptedSubmit: false,
      user,
      accountNumber: this.props.location.state?.accountNumber,
      generalErrors: [],
      formErrors: {},
    };

    this.getPartnerInfo(this.props.partnerId);
  }

  componentDidMount() {
    if (this.props.location.state?.accountNumber && this.state.user) {
      this.updatePartner(this.props.partnerId, this.props.location.state?.accountNumber);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.location.state?.accountNumber && this.props.location.state?.accountNumber && this.state.user) {
      this.updatePartner(this.props.partnerId, this.props.location.state?.accountNumber);
    }
  }

  getPartnerInfo = async (partnerProgram) => {
    try {
      const urls = await getPartnerInfo(partnerProgram);
      this.setState({
        loginUrl: urls.loginUrl,
        registerUrl: urls.registerUrl,
      });
    } catch (err) {
      const result = processApiError(err, Data.apiFieldErrorMessages);
      this.setState({
        generalErrors: result.generalMessages,
        formErrors: result.fieldMessages
      });
    }

  }

  updatePartner = async (partnerProgram, memberId) => {
    try {
      const { token } = getUser();
      const user = await updatePartner(token, partnerProgram, memberId);

      saveUser(user);
      
      if(isFuelJaLto()) {
        this.props.history.replace('/fuelrewardslto/success');
      } else if(isFuelJa()) {
        this.props.history.replace('/fuelrewards/success');
      }
    } catch (err) {
      const result = processApiError(err, Data.apiFieldErrorMessages);
      this.setState({
        generalErrors: result.generalMessages,
        formErrors: result.fieldMessages
      });
    }
  }

  handleValueChange = (e) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [e.target.name]: e.target.value,
        },
      }
    });
  };

  onLoginSuccess = (user) => {
    this.setState({ user });
  };

  render() {
    const user = this.state.user;
    if (!user) {
      return <SignIn
        match={this.props.match}
        location={this.props.location}
        history={this.props.history}
        onLoginSuccess={this.onLoginSuccess}
      />;
    }

    if (this.props.location.state?.accountNumber) {
      // we are waiting on the updatePartner request
      return null;
    }

    let callbackUrl = `${window.location.origin}${this.props.match.path}/`;
    return (
      <main className="main-content">
        <section className="hero-component" style={{ backgroundImage: "url('/jamba/fuel-hero.png')" }}></section>

        <section className="container">
          <div className="row mb-medium">
            <h1 className="section-title">Step 2: Sign in or sign up for a Fuel Rewards<sup>®</sup> account</h1>
          </div>
          <div className="row">
            <div className="col">
              <div className="card-component secondary-style">
                <form action={this.state.registerUrl} method="POST">
                  <input type="hidden" name="callBackUrl" value={callbackUrl} />
                  <div className="content-wrapper">
                    <h3 className="title">Join the Fuel Rewards<sup>®</sup> program</h3>
                  </div>
                  <button className="primary-button" type="submit">Join now</button>
                </form>
              </div>
            </div>
            <div className="col">
              <div className="card-component secondary-style">
                <form action={this.state.loginUrl} method="POST">
                  <input type="hidden" name="callBackUrl" value={callbackUrl} />
                  <div className="content-wrapper">
                    <h3 className="title">Sign into your Fuel Rewards<sup>®</sup> account</h3>
                  </div>
                  <button className="primary-button" type="submit">Sign in</button>
                </form>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="disclaimer-component">
              <p>
                *Restrictions apply. Offer valid from November 13, 2023 – Februrary 28, 2025 (the "Offer Period"). Earn a one-time 15¢/gal Fuel Rewards® savings up to 20 gallons (the "Linking Reward") when you successfully link your Fuel Rewards® account to your Jamba Rewards account. To qualify for this offer, during the Offer Period you must: (i) be a member of or join the JambaRewards program; (ii) be a member of or join the Fuel Rewards®program; (iii) link your Jamba Rewards account to your Fuel Rewards® account using the link above. After successful linking, swipe your Fuel Rewards® card, use a linked payment card, or enter your Alt ID when you fuel at a participating Shell station to redeem the Linking Reward. The Fuel Rewards® Linking Reward is single use and expires on the last day of the month following the month in which the Linking Reward was earned. Please see <a href="https://www.fuelrewards.com/" target="_blank">fuelrewards.com/</a> status for complete Fuel Rewards® Tier Status details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
                <br /><br />
                †Restrictions apply. Offer valid from November 13, 2023 – Februrary 28, 2025 (the "Offer Period"). Earn 5¢/gal in Fuel Rewards® savings up to 20 gallons (the “Spend and Get Reward”) when you spend $25.00 in qualifying purchases at participating Jamba locations. To qualify for this offer, (i) the user must have successfully linked their Jamba Rewards account to their Fuel Rewards® account; and (ii) spend $25.00 in qualifying purchases (excluding purchasing gift cards, taxes and fees) at Jamba using their Jamba Rewards account qualifying purchases may be completed via multiple transactions. Each time the Jamba User spends $25 in qualifying purchases, the user is issued a Spend and Get Reward. To redeem the Spend and Get Reward,use a linked payment card or enter your Alt ID when you fuel at a participating Shell station to redeem the reward. Spend and Get Reward will be added to your Fuel Rewards® account within two (2) to three (3) business days of completing each $25.00 qualifying purchase(s) at Jamba using your Jamba Rewards account. All Spend and Get Rewards will expire on the last day of the month, one (1) month after the month in which rewards are posted to your account. Please see <a href="https://www.fuelrewards.com/" target="_blank">fuelrewards.com/</a> for complete Fuel Rewards®  program details and Terms and Conditions and <a href="https://www.jamba.com/legal#rewards-terms" target="_blank">https://www.jamba.com/legal#rewards-terms</a> for Jamba Rewards Terms and Conditions. See below for more terms and conditions.
                <br /><br />
                For all offers listed above, these terms apply. Void where prohibited, only available in US. Fuel Rewards® savings are limited to 20 gallons of fuel per purchase per vehicle or fraud limits placed by Shell and/or limits placed on your payment card by your financial institution, each of which may be lower. Dispenser may require a price of up to $0.109/gallon. Once you begin to dispense fuel using your Fuel Rewards®savings, you must dispense to the 20-gallon limit or you forfeit any remaining discount per gallons. If you have a rewards balance greater than the current per-gallon price of fuel, the price will roll down to the maximum capability of the fuel equipment, and your remaining rewards balance will be saved for a future fuel purchase. For purchases of $75 or more, please go inside to pay. Unbranded diesel and alternative fuels may not be eligible. The Fuel Rewards® program is owned and operated by Excentus Corporation. Offers may be modified or discontinued at any time without notice. Not valid where prohibited by law. Please see <a href="https://www.fuelrewards.com/" target="_blank">www.fuelrewards.com/</a> for complete FuelRewards® program details and Terms and Conditions. Other restrictions may apply. All trademarks are property of their respective owners.
              </p>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

Linking.contextType = BrandContext;
export default withRouter(Linking);